@import '../../styles/variables';

.Accordion .panel {
    background-color: $ashphalt;
    border-top: 1px solid $graphite;
}

.Accordion .panel .Button {
    margin: $padding-standard auto;
}

.Accordion .panel .Input {
    background-color: $graphite;
    height: $condensed;
}
