@import '../../styles/variables';
@import '../../styles/typography';

.FileInput {

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: $standard;
    padding: 3px $padding-standard 0;

    background-color: $ashphalt;

    overflow: hidden;

}

.FileInput h4 {
    @extend .Default;
}

.FileInput input {
    display: none;
}

.FileInput label {

    @extend .Default;

    color: $guacamole;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 700;
    text-align: right;

    appearance: none;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;

    flex: 1;
    height: 100%;
    padding-left: $padding-standard;

    display: flex;
    align-items: center;
    justify-content: flex-end;

}
