@import '../../styles/variables';
@import '../../styles/typography';

.Scorecard {
    width: 100%;
    margin-top: ($padding-large * 2) + $condensed + $standard + $padding-standard;
}

.Scorecard.complete {
    padding-top: calc(#{$standard} + 20vw);
    pointer-events: none;
}

.Scorecard.complete.removeSticky .ScorecardSticky {
    position: relative;
    top: 0;
}

.Scorecard .scoreSelector {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $score;

    background-color: rgba($color: $midnight, $alpha: .75);

    display: none;
    align-items: flex-end;

    padding: $padding-standard;

}

.Scorecard .scoreSelector.active {
    display: flex;
}

.Scorecard .scoreSelector div.close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.Scorecard .scoreSelector div.panel {

    display: flex;
    flex-wrap: wrap;

    width: 100%;

}

.Scorecard .scoreSelector h5 {

    width: 100%;

    @extend .Notification_Text;
    line-height: 30px;
    text-align: center;
    font-weight: 300;

    background-color: $ashphalt;

}

.Scorecard .scoreSelector h5 strong {
    font-weight: 700;
}

.Scorecard .scoreSelector div.panel button.s {

    width: 25%;
    height: calc((100vw - (#{$padding-standard} * 2)) / 4);

    border-radius: 0;
    appearance: none;
    border: none;
    background: $ashphalt;
    outline: none;

    border-top: 1px solid $graphite;
    border-right: 1px solid $graphite;

    @extend .ScorecardMain;

    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: $buttermilk;

    &:nth-of-type(4n + 4) {
        border-right: none;
    }

    &:active {
        background: $guacamole;
    }

}

.Scorecard .scoreSelector div.panel div.clear {

    width: 100%;
    padding: $padding-large 0;

    background-color: $ashphalt;
    border-top: 1px solid $graphite;

}

.Scorecard > .Button {
    margin: $padding-large auto;
}
