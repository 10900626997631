@import '../../styles/variables';
@import '../../styles/typography';

.CourseRow {

    width: 100%;
    height: $standard;
    padding: 0 $padding-standard;

    background-color: $ashphalt;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.CourseRow.complete {
    background-color: $graphite;
}

.CourseRow .left {

    display: flex;
    align-items: center;
    flex: 1;

    padding-right: $padding-standard;

}

.CourseRow .left .icon {

    display: block;

    width: 30px;
    height: 30px;
    padding: 1px 0 0 1px;
    margin-right: 10px;

    border-radius: 50%;

    // border: 1px solid $guacamole;
    background-color: $graphite;
    box-shadow: $box-shadow;

    display: flex;
    align-items: center;
    justify-content: center;

    @extend .CTA;
    font-family: 'Rammetto One', sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;

}

.CourseRow h2 {
    @extend .Default;
}

.CourseRow .left h3 {

    @extend .Notification_Text;
    font-size: 12px;
    color: $guacamole;

    margin-top: 1px;

}

.CourseRow .left > div {
    padding-top: 2px;
    flex: 1;
}

.CourseRow .right {
    padding-top: 2px;
}

.CourseRow .right h2 {
    font-weight: 700;
    color: $guacamole;
}

.CourseRow .right img {
    width: 15px;
    height: 15px;
}
