@import '../../styles/variables';
@import '../../styles/typography';

.MatchPreview .header {

    background-color: $buttermilk;

    height: 30px;
    padding: 0 $padding-standard;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.MatchPreview .header h3 {

    @extend .CTA;
    color: $midnight;

    margin-top: 3px;

}

.MatchPreview .golfers {
    background-color: $graphite;
    padding: $padding-standard 0;
}

.MatchPreview.leaderboard .golfers {
    background-color: $ashphalt;
}

.MatchPreview .golfers .GolferRow {
    background-color: $graphite;
    height: $condensed;
}

.MatchPreview.leaderboard .golfers .GolferRow {
    background-color: $ashphalt;
}

.MatchPreview .golfers .versus {

    @extend .MetaTextSub;
    font-weight: 700;
    color: $buttermilk;

    display: flex;
    align-items: center;

    padding: 0 $padding-standard;
    margin: ($padding-small * 2) 0;

}

.MatchPreview .golfers .versus span {
    width: 50px;
    text-align: center;
}

.MatchPreview .golfers .versus:before,
.MatchPreview .golfers .versus:after {

    content: '';

    flex: 1;
    height: 1px;

    background-color: $buttermilk;

}

.MatchPreview .golfers .team {
    position: relative;
}

.MatchPreview .golfers .team .score {

    position: absolute;
    top: 50%;
    right: $padding-standard;

    z-index: 5;

    transform: translateY(-50%);

    font-weight: 700;
    color: $guacamole;

}
