@import '../../styles/variables';
@import '../../styles/typography';

.SummaryRow .totals {

    display: flex;
    align-items: center;

    height: $standard;

    background-color: $ashphalt;
    border-top: 1px solid $graphite;

}

.SummaryRow .totals > div {
    width: calc(100% / 6);
    text-align: center;
}

.SummaryRow .totals h4 {
    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
}

.SummaryRow .totals h5 {

    @extend .Notification_Text;
    font-weight: 700;
    color: $guacamole;

    margin-top: 5px;

}
