@import '../../styles/variables';
@import '../../styles/typography';

.StatLine {

    width: 100%;
    height: $condensed;
    padding: 0 $padding-standard;
    margin-bottom: $padding-small;

    background-color: $ashphalt;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-of-type { margin-bottom: 0; }

}

.StatLine .labels {

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 5px;

    @extend .Notification_Text;
    font-size: 12px;
    font-weight: 300;

}

.StatLine h4 {
    display: inline-block;
}

.StatLine h4 strong {
    font-weight: 700;
    margin-right: 5px;
}

.StatLine .bar {

    width: 100%;
    height: 6px;

    background-color: $graphite;
    border-radius: 6px;

}

.StatLine .bar .fill {

    height: 100%;
    border-radius: inherit;

    background-color: $guacamole;

}
