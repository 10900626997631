@import '../../styles/variables';
@import '../../styles/typography';

.TournamentRoundList {
    padding: $padding-standard $padding-standard (50px + ($padding-standard * 2));
}

.TournamentRoundList .CourseRow {
    margin-bottom: $padding-small;
}

.TournamentRoundList a:last-of-type .CourseRow {
    // margin-bottom: 0;
}

.TournamentRoundList .Button {
    margin-top: $padding-large;
}

.TournamentRoundList .ActionButton[data-id="info"] {
    position: fixed;
    bottom: $padding-standard;
    left: $padding-standard;
}
