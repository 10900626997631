@import '../../styles/variables';
@import '../../styles/typography';

.CreateTeeTime {
    padding: $padding-standard $padding-standard (50px + ($padding-standard * 2));
}

.CreateTeeTime .Input {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.CreateTeeTime .Button {
    margin-top: $padding-large;
}
