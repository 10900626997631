@import '../../styles/variables';
@import '../../styles/typography';

.Play {
    padding: 0 $padding-standard (50px + ($padding-standard * 2));
}

.Play .CourseRow {
    margin-bottom: $padding-small;
}

.Play a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.Play .ActionButton[data-id="create"] {
    position: fixed;
    bottom: $padding-standard;
    right: $padding-standard;
}
