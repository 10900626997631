@import '../../styles/variables';
@import '../../styles/typography';

.Modal {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $loader;

    background-color: rgba(5, 5, 5, 0.9);

    display: none;

    padding: $padding-standard;

}

.Modal.visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal .panel {
    background-color: $ashphalt;
    padding: $padding-standard;
}

.Modal .copy {

    @extend .Default;
    line-height: 1.2;

    position: relative;

    padding: $padding-standard;

}

.Modal .copy .corner {

    position: absolute;

    width: 10px;
    height: 10px;

    border: 1px solid $guacamole;

}

.Modal .copy .corner:nth-of-type(1) {

    top: 0;
    left: 0;

    border-right: none;
    border-bottom: none;

}

.Modal .copy .corner:nth-of-type(2) {

    top: 0;
    right: 0;

    border-left: none;
    border-bottom: none;

}

.Modal .copy .corner:nth-of-type(3) {

    bottom: 0;
    left: 0;

    border-right: none;
    border-top: none;

}

.Modal .copy .corner:nth-of-type(4) {

    bottom: 0;
    right: 0;

    border-left: none;
    border-top: none;

}

.Modal .copy p {
    margin-bottom: 20px;
    &:last-of-type { margin-bottom: 0; }
}

.Modal .Button {
    width: 100%;
    margin: $padding-large 0 $padding-standard;
}
