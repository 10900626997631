@import '../../styles/variables';
@import '../../styles/typography';

.Button {

    @extend .CTA;

    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: $midnight;

    display: block;

    appearance: none;
    border: none;
    border-radius: $condensed;
    outline: none;

    background: none;
    background-color: $guacamole;

    width: calc(100% - (#{$padding-standard} * 2));
    height: $condensed;
    margin: 0 auto;
    padding-top: 5px;

    cursor: pointer;

}

.Button:disabled {
    opacity: $disabled;
}
