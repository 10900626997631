@import '../../styles/variables';

.ConnectionWarning {

    position: fixed;
    top: 0;
    left: 0;
    z-index: $loader;

    width: 100%;
    height: 100%;
    padding: $padding-standard;

    background-color: $midnight75;
    backdrop-filter: blur(3px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

}

.ConnectionWarning img {
    width: 32px;
    margin-bottom: $padding-standard;
}

.ConnectionWarning h3 {

    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $huckleberry;

    margin-bottom: $padding-standard;

}

.ConnectionWarning p {
    
    line-height: 1.2;
    
    margin-bottom: $padding-large;
    padding: 0 $padding-standard;

}

.ConnectionWarning .Button {
    background-color: $buttermilk;
}