@import '../../styles/variables';
@import '../../styles/typography';

.Round {
    padding: 0 $padding-standard;
}

.Round .tabWrapper {

    position: fixed;
    top: $standard;
    left: 0;
    z-index: 10;

    width: 100%;
    height: ($padding-large * 2) + $condensed;
    padding: 0 $padding-standard;

    background: $midnight;

}

.Round .Summary {
    margin-top: ($padding-large * 2) + $condensed;
    padding-bottom: (50px + ($padding-standard * 2));
}

.Round .Summary .SummaryRow {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.Round .Summary .ActionButton {
    position: fixed;
    bottom: $padding-standard;
    left: $padding-standard;
}
