@import '../../styles/variables';
@import '../../styles/typography';

.TeeTimePreview {
    background-color: $ashphalt;
}

.TeeTimePreview div.golfers {

    border-top: 1px solid $graphite;

    display: flex;
    flex-wrap: wrap;

}

.TeeTimePreview .GolferRow {

    width: 50%;

    border: 1px solid $graphite;
    border-top: none;
    border-left: none;

    &:nth-child(even) {
        border-right: none;
    }

}

.TeeTimePreview div.golfers.c1 .GolferRow {
    &:nth-child(1) {
        border-bottom: none;
    }
}

.TeeTimePreview div.golfers.c2 .GolferRow {
    &:nth-child(1),
    &:nth-child(2) {
        border-bottom: none;
    }
}

.TeeTimePreview div.golfers.c3 .GolferRow {
    &:nth-child(3) {
        border-bottom: none;
    }
}

.TeeTimePreview div.golfers.c4 .GolferRow {
    &:nth-child(3),
    &:nth-child(4) {
        border-bottom: none;
    }
}

.TeeTimePreview div.button {
    padding: $padding-large 0;
    border-top: 1px solid $graphite;
}
