@import '../../styles/variables';
@import '../../styles/typography';

.LeaderboardRow {

    background-color: $ashphalt;

    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }

}

.LeaderboardRow .scoreRow {

    width: 100%;
    height: $standard;
    padding: 0 0 0 $padding-standard;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.LeaderboardRow.results .scoreRow {
    padding: 0 10px 0 $padding-standard;
}

.LeaderboardRow .cardRow {
    border-top: 1px solid $graphite;
    padding-bottom: 10px;
}

.LeaderboardRow .cardRow .holes,
.LeaderboardRow .cardRow .pars,
.LeaderboardRow .cardRow .scores {

    display: flex;
    align-items: center;

    width: 100%;
    height: 30px;

    @extend .Notification_Text;

}

.LeaderboardRow .cardRow .holes {
    font-weight: 300;
    &:nth-child(4) { margin-top: $padding-standard; }
}

.LeaderboardRow .cardRow .pars,
.LeaderboardRow .cardRow .scores {
    font-weight: 700;
}

.LeaderboardRow .cardRow .scores {
    background-color: $graphite;
}

.LeaderboardRow .cardRow .holes > span,
.LeaderboardRow .cardRow .pars > span,
.LeaderboardRow .cardRow .scores > span {

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 10%;
    height: 30px;
    padding-top: 3px;
    flex-grow: 1;

    text-align: center;

}

.LeaderboardRow .cardRow .scores > span:before {

    content: '';

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;

    transform: translateX(-50%) translateY(-50%);

    width: 22px;
    height: 22px;

}

.LeaderboardRow .cardRow .scores > span > span {
    position: relative;
    z-index: 5;
}

.LeaderboardRow .cardRow .scores span.bogey:before,
.LeaderboardRow .cardRow .scores span.double:before {
    border: 1px solid $huckleberry;
}

.LeaderboardRow .cardRow .scores span.double:before {
    background-color: $huckleberry;
}

.LeaderboardRow .cardRow .scores span.birdie:before,
.LeaderboardRow .cardRow .scores span.eagle:before {
    border: 1px solid $guacamole;
    border-radius: 50%;
}

.LeaderboardRow .cardRow .scores span.eagle:before {
    background-color: $guacamole;
}

.LeaderboardRow.results {
    // padding: 0 5px 0 $sides;
}

.LeaderboardRow h3 {

    font-size: 20px;
    line-height: 1;
    font-weight: 700;

    width: 16px;

}

.LeaderboardRow span.icon {

    display: block;

    width: 30px;
    height: 30px;
    margin: 0 10px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: $box-shadow;

    background-size: cover;
    background-position: center center;
    background-color: $graphite;

    @extend .CTA;
    font-family: 'Rammetto One', sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;

}

.LeaderboardRow span.icon.hidden {
    opacity: 0;
}

.LeaderboardRow div.golfer,
.LeaderboardRow div.tallies {
    display: flex;
    align-items: center;
}

.LeaderboardRow div.golfer div.name {
    flex: 1;
}

.LeaderboardRow div.golfer div.name h4 {
    @extend .Default;
}

.LeaderboardRow div.golfer div.name h5 {

    @extend .Notification_Text;
    font-size: 12px;
    color: $guacamole;

    margin-top: 1px;

}

.LeaderboardRow div.tallies h5 {

    @extend .Default;
    font-weight: 700;
    text-align: center;

    width: $leaderboard-tally-width;
    height: $leaderboard-tally-width;
    padding-top: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

}

.LeaderboardRow div.tallies h5:first-of-type {
    background-color: $graphite;
}

.LeaderboardRow div.tallies h5:nth-of-type(2) {
    padding-left: 5px;
}

.LeaderboardRow div.tallies div.results h5 {
    // width: $leaderboard-tally-width / 1.5;
}

.LeaderboardRow div.tallies > div {
    display: flex;
}

.LeaderboardRow.linkOut:active {
    background-color: $guacamole;
}

.LeaderboardRow.linkOut:active .DashedBorder div.top {
    background-image: linear-gradient(to left, $buttermilk 50%, transparent 0%);
}

.LeaderboardRow.linkOut:active .DashedBorder div.bottom {
    background-image: linear-gradient(to right, $buttermilk 50%, transparent 0%);
}

.LeaderboardRow.linkOut:active .DashedBorder div.left {
    background-image: linear-gradient(to bottom, $buttermilk 50%, transparent 0%);
}

.LeaderboardRow.linkOut:active .DashedBorder div.right {
    background-image: linear-gradient(to top, $buttermilk 50%, transparent 0%);
}

.LeaderboardRow .roundsRow {

    display: flex;
    align-items: center;

    height: $standard;

    background-color: $ashphalt;
    border-top: 1px solid $graphite;

}

.LeaderboardRow .roundsRow > div {

    flex-basis: 0;
    flex-grow: 1;

    text-align: center;

}

.LeaderboardRow .roundsRow h4 {
    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
}

.LeaderboardRow .roundsRow h5 {

    @extend .Notification_Text;
    font-weight: 700;
    color: $guacamole;

    margin-top: 5px;

}
