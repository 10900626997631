@import '../../styles/variables';
@import '../../styles/typography';

.ScorecardHole {
    margin-bottom: $padding-small * 2;
    &:last-of-type { margin-bottom: 0; }
}

.ScorecardHole .hole {
    display: flex;
    width: 100%;
}

.ScorecardHole .hole > div {

    width: 25%;
    height: calc((100vw - (#{$padding-standard} * 2)) / 4);

    background-color: $ashphalt;
    border-right: 1px solid $graphite;

}

.ScorecardHole .hole.total > div {
    background-color: $graphite;
    border-right: 1px solid $ashphalt;
}

.ScorecardHole .hole > div:last-of-type,
.ScorecardHole .hole.total > div:last-of-type {
    border-right: none;
}

.ScorecardHole .hole div.dummy {
    pointer-events: none;
}

.ScorecardHole .holeDetails {

    width: 100%;
    height: 30px;

    background-color: $ashphalt;
    border-bottom: 1px solid $graphite;

    display: flex;

}

.ScorecardHole .holeDetails.total {
    background-color: $graphite;
    border-bottom: 1px solid $ashphalt;
}

.ScorecardHole .holeDetails span {

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25%;
    padding-top: 3px;
    flex-grow: 1;

    @extend .Notification_Text;
    color: $buttermilk;
    font-weight: 300;

}

.ScorecardHole .holeDetails span:first-of-type {

    @extend .CTA;
    color: $midnight;

    background-color: $buttermilk;

}

.ScorecardHole .holeDetails span.sum {
    background-color: $guacamole;
}

.ScorecardHole .holeDetails span strong {
    font-weight: 700;
    margin-left: 3px;
}
