@import '../../styles/variables';
@import '../../styles/typography';

.NavBar {

    position: fixed;
    top: 0;
    left: 0;
    z-index: $navbar;

    background-color: $midnight;

    width: 100%;
    height: $standard;
    padding: 0 $padding-standard;

    display: flex;
    align-items: center;
    justify-content: space-between;

    box-shadow: $box-shadow;
    border-bottom: 1px solid $concrete;

}

.NavBar.open a {
    visibility: hidden;
}

.NavBar h1.title-treatment {
    font-family: 'Rammetto One', sans-serif;
    font-size: 30px;
    line-height: 1;
    letter-spacing: -1px;
}

.NavBar h1.title-treatment span {
    color: $guacamole;
}

.NavBar div.hamburglar {

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.NavBar div.hamburglar div.patty {

    height: 2px;
    background-color: $buttermilk;

    transition: all $duration ease;

}

.NavBar div.hamburglar div.patty:nth-of-type(1) {
    width: 24px;
}

.NavBar div.hamburglar div.patty:nth-of-type(2) {

    width: 20px;
    margin: 6px 0;

    transform-origin: right center;
    // transition-delay: $duration / 2;

}

.NavBar div.hamburglar div.patty:nth-of-type(3) {
    width: 16px;
}

.NavBar div.hamburglar.close div.patty:nth-of-type(1) {
    transform: translateY(8px) rotate(45deg);
    // transition-delay: $duration;
}

.NavBar div.hamburglar.close div.patty:nth-of-type(2) {
    transform: scaleX(0);
}

.NavBar div.hamburglar.close div.patty:nth-of-type(3) {

    width: 24px;

    transform: translateY(-8px) rotate(-45deg);
    // transition-delay: $duration;

}

.NavBar h2 {

    position: absolute;
    top: calc(50% + 2px);
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    @extend .CTA;
    text-align: center;

}
