@import '../../styles/variables';
@import '../../styles/typography';

.Leaderboard {
    width: 100%;
    margin-top: ($padding-large * 2) + $condensed + 30px + 3px;
    padding-bottom: (50px + ($padding-standard * 2));
}

.Leaderboard .ActionButton {
    position: fixed;
    bottom: $padding-standard;
    left: $padding-standard;
}

.Leaderboard .AltHeader {

    width: 100%;
    height: 30px;
    padding: 3px 0 0 $padding-standard;
    margin: $padding-standard 0 $padding-small;

    // background-color: $ashphalt;

    display: flex;
    align-items: center;

}

.Leaderboard .AltHeader h4 {
    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
    color: $buttermilk;
}

.Leaderboard .MatchPreview {
    margin-bottom: ($padding-small * 2);
}
