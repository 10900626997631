@import '../../styles/variables';
@import '../../styles/typography';

.Golfers {
    padding: 0 $padding-standard (50px + ($padding-standard * 2));
}

.Golfers .GolferRow {
    margin-bottom: $padding-small;
}

.Golfers a:last-of-type .GolferRow {
    margin-bottom: 0;
}

.Golfers .ActionButton {
    position: fixed;
    bottom: $padding-standard;
    right: $padding-standard;
}

.Golfers .Notification {
    margin-top: $padding-standard;
}
