@import '../../styles/variables';
@import '../../styles/typography';

.Golfer {
    padding-bottom: (50px + ($padding-standard * 2));
}

.Golfer .hero {

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: $padding-standard;

    text-align: center;

}

.Golfer .hero:before,
.Golfer .hero:after {

    content: '';

    position: absolute;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 50%;

}

.Golfer .hero:before {
    top: 0;
    background-color: $graphite;
}

.Golfer .hero:after {
    bottom: 0;
    background-color: $ashphalt;
}

.Golfer .hero .icon {

    width: $standard * 2;
    height: $standard * 2;
    padding-top: 8px;

    border-radius: 50%;

    background-color: $graphite;
    background-size: cover;
    background-position: center center;

    box-shadow: 0 0 25px rgba(5, 5, 5, 0.5);

    font-family: 'Rammetto One', sans-serif;
    letter-spacing: 0;
    font-size: 60px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

}

.Golfer .hero h2 {

    @extend .Default;
    font-size: 18px;

    margin-bottom: 2px;

}

.Golfer h3 {
    @extend .Notification_Text;
    color: $guacamole;
}

.Golfer .hero .handicap {

    display: flex;
    justify-content: space-between;

    width: 50%;

}

.Golfer .hero .handicap > div:first-of-type {
    transform: translateX(-50%);
}

.Golfer .hero .handicap > div:last-of-type {
    transform: translateX(50%);
}

.Golfer .hero h4 {
    @extend .CTA;
    font-size: 40px;
}

.Golfer .hero .name {
    margin-bottom: $padding-standard;
}

.Golfer .main {
    padding: $padding-standard $padding-standard 0;
}

.Golfer .Notification {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.Golfer a .CourseRow {
    margin-bottom: $padding-small;
}

.Golfer a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.Golfer .ActionButton {
    position: fixed;
    bottom: $padding-standard;
    right: $padding-standard;
}
