@import '../../styles/variables';
@import '../../styles/typography';

.ScorecardScore {

    position: relative;

    text-align: center;
    overflow: hidden;

    background-color: $ashphalt;

    display: flex;
    align-items: center;
    justify-content: center;

}

.ScorecardScore div.dots {

    display: flex;

    position: absolute;
    top: 7px;
    left: 7px;

}

.ScorecardScore div.dots div {

    width: 6px;
    height: 6px;

    background-color: $guacamole;
    border-radius: 50%;

    margin-right: 2px;

}

.ScorecardScore div.net {

    position: absolute;
    top: 5px;
    right: 6px;

    font-size: 12px;
    line-height: 1;

}

.ScorecardScore div.gross {

    @extend .ScorecardMain;

    position: relative;

    width: 40px;
    height: 40px;
    padding-top: 6px;

}

.ScorecardScore div.gross.eagle,
.ScorecardScore div.gross.birdie {
    border: 1px solid $guacamole;
    border-radius: 50%;
}

.ScorecardScore div.gross.bogey,
.ScorecardScore div.gross.double {
    border: 1px solid $huckleberry;
}

.ScorecardScore div.gross.eagle {
    background-color: $guacamole;
}

.ScorecardScore div.gross.double {
    background-color: $huckleberry;
}
