@import '../../styles/variables';
@import '../../styles/typography';

.Input {

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: $standard;
    padding: 3px $padding-standard 0;

    background-color: $ashphalt;

    overflow: hidden;

}

.Input h4 {
    @extend .Default;
}

.Input input,
.Input select {

    @extend .Default;

    color: $guacamole;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 700;
    text-align: right;

    appearance: none;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;

    flex: 1;
    height: 100%;
    padding-left: $padding-standard;

    &:disabled {
        opacity: 1 !important;
    }

}

.Input input::placeholder {
    color: $guacamole;
    opacity: 1;
}

.Input input:-webkit-autofill,
.Input input:-webkit-autofill:hover,
.Input input:-webkit-autofill:focus,
.Input input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $ashphalt inset !important;
}

.Input input:-webkit-autofill {
    -webkit-text-fill-color: $guacamole !important;
}
