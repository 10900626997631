@import '../../styles/variables';
@import '../../styles/typography';

.Tab {

    width: calc(100% - (#{$padding-standard} * 2));
    height: $condensed;
    margin: $padding-large auto;

    border: 2px solid $guacamole;
    border-radius: $condensed;

    background-color: $ashphalt;

    display: flex;

}

.Tab .left,
.Tab .right {

    width: 50%;
    height: 100%;
    padding-top: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: $condensed;

}

.Tab .left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.Tab .right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.Tab span {

    display: block;

    width: 100%;
    text-align: center;

    @extend .CTA;

}

.Tab .left.selected,
.Tab .right.selected {
    background-color: $guacamole;
    color: $midnight;
}

.Tab .left:not(.selected),
.Tab .right:not(.selected) {
    opacity: 0.3;
}
