@import '../../styles/variables';
@import '../../styles/typography';

.Rounds {
    padding: 0 $padding-standard $padding-standard;
}

.Rounds .CourseRow {
    margin-bottom: $padding-small;
}

.Rounds a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.Rounds .Notification {
    margin-top: $padding-standard;
}
