@import '../../styles/variables';
@import '../../styles/typography';

.CreateRound {
    padding: 0 $padding-standard (50px + ($padding-standard * 2));
}

.CreateRound .CourseRow {
    margin-bottom: $padding-small;
}

.CreateRound a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.CreateRound .Notification {
    margin: $padding-standard 0;
}

.CreateRound .Input {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.CreateRound .Button {
    margin-top: $padding-large;
}

.CreateRound .ActionButton[data-id="info"] {
    position: fixed;
    bottom: $padding-standard;
    left: $padding-standard;
}
