@import '../../styles/variables';
@import '../../styles/typography';

.Courses {
    padding: 0 $padding-standard (50px + ($padding-standard * 2));
}

.Courses a .CourseRow {
    margin-bottom: $padding-small;
}

.Courses a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.Courses .ActionButton[data-id="create"] {
    position: fixed;
    bottom: $padding-standard;
    right: $padding-standard;
}

.Courses .Notification {
    margin-top: $padding-standard;
}
