@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Rammetto+One');

@import './variables';

.MenuItemHeader {
    color: $buttermilk;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
}

.MetaTextMain {
    font-size: 15px;
    line-height: 15px;
}

.MetaTextSub {
    font-size: 11px;
    line-height: 12px;
}

.StepHeaderText {
    color: $buttermilk;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
}

.SubheaderText {
    color: $buttermilk;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
    text-transform: uppercase;
}

.ScorecardHoleMain {
    color: $buttermilk;
    font-size: 32px;
    font-weight: 300;
    line-height: 30px;
}

///

.CTA {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.Default {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.5px;
}

.Notification_Text {

    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;

    @media (max-width: 320px) {
        font-size: 12px;
    }

}

.ScorecardMain {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
}
