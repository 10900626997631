@import '../../styles/variables';
@import '../../styles/typography';

.CreateCourseHeader {

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;

    width: 100%;
    height: 30px;
    padding-top: 3px;

    background-color: $ashphalt;
    box-shadow: $box-shadow;

    display: flex;
    align-items: center;

}

.CreateCourseHeader h4 {
    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
}

.CreateCourseHeader h4:first-of-type {
    width: $condensed;
}

.CreateCourseHeader h4:not(:first-of-type) {
    width: calc((100% - #{$condensed}) / 3);
}
