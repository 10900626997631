@import '../../styles/variables';
@import '../../styles/typography';

.HoleRow {

    height: $condensed;

    display: flex;
    align-items: center;

    background-color: $ashphalt;

}

.HoleRow h3 {

    width: $condensed;
    height: 100%;
    padding-top: 3px;

    background-color: $buttermilk;

    @extend .CTA;
    color: $midnight;

    display: flex;
    align-items: center;
    justify-content: center;

}

.HoleRow > div {
    width: calc((100% - #{$condensed}) / 3);
    height: 100%;
}

.HoleRow .Input {

    height: 100%;

    background-color: transparent;
    border: none;

}

.HoleRow .Input input,
.HoleRow .Input select {

    text-align-last: center;
    text-align: center;
    color: $guacamole;

    width: 100%;

}

.HoleRow .Input input::placeholder {
    color: $guacamole;
}
