@import '../../styles/variables';
@import '../../styles/typography';

.EditGolfer {
    padding: $padding-standard $padding-standard ($padding-standard * 2);
}

.EditGolfer .Button {
    margin-top: ($padding-standard * 2);
}

.EditGolfer .Input {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}
