@import '../../styles/variables';
@import '../../styles/typography';

.Course {
    padding-bottom: (50px + ($padding-standard * 2));
}

.Course .hero {

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: $padding-standard;

    text-align: center;

}

.Course .hero:before,
.Course .hero:after {

    content: '';

    position: absolute;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 50%;

}

.Course .hero:before {
    top: 0;
    background-color: $graphite;
}

.Course .hero:after {
    bottom: 0;
    background-color: $ashphalt;
}

.Course .hero .icon {

    width: $standard * 2;
    height: $standard * 2;
    padding-top: 8px;

    border-radius: 50%;
    background-color: $graphite;

    box-shadow: 0 0 25px rgba(5, 5, 5, 0.5);

    font-family: 'Rammetto One', sans-serif;
    letter-spacing: 0;
    font-size: 60px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

}

.Course .hero h2 {

    @extend .Default;
    font-size: 18px;

    margin-bottom: 2px;

}

.Course h3 {
    @extend .Notification_Text;
    color: $guacamole;
}

.Course .hero .par {

    display: flex;
    justify-content: space-between;

    width: 50%;

}

.Course .hero .par > div:first-of-type {
    transform: translateX(-50%);
}

.Course .hero .par > div:last-of-type {
    transform: translateX(50%);
}

.Course .hero h4 {
    @extend .CTA;
    font-size: 40px;
}

.Course .hero .name {
    margin-bottom: $padding-standard;
}

.Course .main {
    padding: 0 $padding-standard;
}

.Course .Input {
    margin-bottom: $padding-standard;
}

.Course .Notification {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.Course .ActionButton[data-id="info"],
.Course .ActionButton[data-id="edit"] {
    position: fixed;
    bottom: $padding-standard;
}

.Course .ActionButton[data-id="info"] {
    left: $padding-standard;
}

.Course .ActionButton[data-id="edit"] {
    right: $padding-standard;
}
