@import '../../styles/variables';
@import '../../styles/typography';

.App, .App #routeWrapper {
    height: 100%;
}

.App #routeWrapper {

    padding-top: $standard;

    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

}
