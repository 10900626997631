@import '../../styles/variables';
@import '../../styles/typography';

.Tournaments {
    padding: $padding-standard $padding-standard (50px + ($padding-standard * 2));
}

.Tournaments a .CourseRow {
    margin-bottom: $padding-small;
}

.Tournaments a:last-of-type .CourseRow {
    margin-bottom: 0;
}

.Tournaments .ActionButton[data-id="info"],
.Tournaments .ActionButton[data-id="create"] {
    position: fixed;
    bottom: $padding-standard;
}

.Tournaments .ActionButton[data-id="info"] {
    left: $padding-standard;
}

.Tournaments .ActionButton[data-id="create"] {
    right: $padding-standard;
}
