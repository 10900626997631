@import '../../styles/variables';
@import '../../styles/typography';

.TeeTime {
    // padding: 0 $padding-standard (50px + ($padding-standard * 2));
    padding: 0 $padding-standard;
}

.TeeTime .tabWrapper {

    position: fixed;
    top: $standard;
    left: 0;
    z-index: 10;

    width: 100%;
    height: ($padding-large * 2) + $condensed;
    padding: 0 $padding-standard;

    background: $midnight;

}
