@import '../../styles/variables';
@import '../../styles/typography';

.Home {
    position: relative;
    height: 100%;
}

.Home .Dashboard,
.Home .Auth {
    height: 100%;
}

.Home .Auth {

    position: relative;

    background-image: url('/images/home/bg.jpg');
    background-size: cover;
    background-position: center center;

    padding: 0 $padding-standard;

}

.Home .Auth:after {

    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, .85);

}

.Home .Auth > div {

    position: relative;
    top: 40%;
    z-index: 5;

    transform: translateY(-$standard) translateY(-$padding-large * 2) translateY(-$condensed);

    width: 100%;

}

.Home .Auth h1.title-treatment {

    position: fixed;
    top: 0;
    left: 0;
    z-index: $navbar;

    background-color: $midnight;
    border-bottom: 1px solid $graphite;

    width: 100%;
    padding: 0 $padding-standard;

    font-family: 'Rammetto One', sans-serif;
    font-size: 30px;
    text-align: center;
    line-height: $standard;
    letter-spacing: -1px;

}

.Home .Auth h1.title-treatment span {
    color: $guacamole;
}

.Home .Auth h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
}

.Home .Auth h3 {

    @extend .Notification_Text;

    color: $huckleberry;
    padding: $padding-standard $padding-standard 0;

}

.Home .Auth .Input:not(:first-of-type) {
    margin-top: $padding-small;
}

.Home .Auth button {
    margin-top: $padding-large;
}

.Home .Dashboard {
    padding: $padding-standard;
}

.Home .Dashboard div.panel {

    display: flex;
    flex-wrap: wrap;

    height: 100%;

    background-color: $ashphalt;

}

.Home .Dashboard div.panel a {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 50%;
    height: calc(100% / 3);

    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $graphite;

}

.Home .Dashboard div.panel a:nth-child(even) {
    border-right: none;
}

.Home .Dashboard div.panel a:nth-child(n + 5) {
    border-bottom: none;
}

.Home .Dashboard div.panel img {
    height: 60px;
}

.Home .Dashboard div.panel h3 {

    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $guacamole;

    margin-top: $padding-standard;

}
