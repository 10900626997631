@import '../../styles/variables';
@import '../../styles/typography';

.CreateCourse {
    // padding: $padding-standard $padding-standard (50px + ($padding-standard * 2))
    padding: $padding-standard;
}

.CreateCourse .Input {
    margin-bottom: $padding-small;
    &:nth-child(5) { margin-bottom: 0; }
}

.CreateCourse .CreateCourseHeader {
    margin: $padding-standard 0 $padding-small;
}

.CreateCourse .HoleRow {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.CreateCourse .Button {
    margin: $padding-standard * 2 auto $padding-standard;
}
