@import '../../styles/variables';
@import '../../styles/typography';

.Notification {

    width: 100%;
    height: $condensed;
    padding: 3px $padding-standard 0;

    background-color: $ashphalt;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.Notification.complete {
    background-color: $graphite;
}

.Notification .key {
    @extend .Notification_Text;
    color: $buttermilk;
}

.Notification .key strong {
    font-weight: 700;
}

.Notification .value {
    @extend .Notification_Text;
    font-weight: 700;
    color: $guacamole;
}

.Notification img {
    width: 15px;
    height: 15px;
}
