@import '../../styles/variables';
@import '../../styles/typography';

.TeeTimes {
    padding: $padding-standard $padding-standard (50px + ($padding-standard * 2));
}

.TeeTimes .ActionButton[data-id="create"],
.TeeTimes .ActionButton[data-id="info"] {
    position: fixed;
    bottom: $padding-standard;
}

.TeeTimes .ActionButton[data-id="create"] {
    right: $padding-standard;
}

.TeeTimes .ActionButton[data-id="info"] {
    left: $padding-standard;
}

.TeeTimes > .Button {
    margin-top: $padding-large;
}

.TeeTimes .TeeTimePreview {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.TeeTimes .Accordion {
    margin-top: $padding-standard;
}

.TeeTimes .Accordion .Button {
    width: 100%;
}

.TeeTimes .Accordion form .Button {
    margin-top: $padding-large;
}

.TeeTimes .Accordion .addMatchHeader {

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: $padding-standard 0;

}

.TeeTimes .Accordion .addMatchHeader h4 {

    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
    white-space: nowrap;
    color: $buttermilk;

    width: $condensed;

    &:last-of-type { text-align: center; }

}

.TeeTimes .Accordion .Input {
    margin-bottom: $padding-small;
}

.TeeTimes .Accordion .addMatchContainer .versus {

    @extend .MetaTextSub;
    font-weight: 700;
    color: $buttermilk;

    display: flex;
    align-items: center;

    margin: ($padding-small * 2) 0 ($padding-small * 3);

}

.TeeTimes .Accordion .addMatchContainer .versus span {
    width: 50px;
    text-align: center;
}

.TeeTimes .Accordion .addMatchContainer .versus:before,
.TeeTimes .Accordion .addMatchContainer .versus:after {

    content: '';

    flex: 1;
    height: 1px;

    background-color: $buttermilk;

}

.TeeTimes .Accordion .addMatchGolfer {
    display: flex;
}

.TeeTimes .Accordion .addMatchGolfer .Input:first-of-type {
    flex: 1;
}

.TeeTimes .Accordion .addMatchGolfer .Input:last-of-type {
    width: $condensed;
    margin-left: $padding-small;
}

.TeeTimes .Accordion .addMatchGolfer .Input:last-of-type input {
    padding-left: 0;
    text-align: center;
}

.TeeTimes .Accordion .MatchPreview {
    margin-bottom: ($padding-small * 2);
    &:last-of-type { margin-bottom: 0; }
}

.TeeTimes .Accordion .addMatchContainer,
.TeeTimes .Accordion .addMatchButtonContainer,
.TeeTimes .Accordion .matchesContainer {
    padding: $padding-standard;
}

.TeeTimes .Accordion .matchesContainer {
    border-bottom: 1px solid $graphite;
}
