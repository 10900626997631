@import '../../styles/variables';
@import '../../styles/typography';

.CreateTournament {
    padding: $padding-standard;
}

.CreateTournament .Button {
    margin-top: $padding-large;
}
