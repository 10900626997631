/* COLORS */
$concrete: #333;
$white: #FFF;
$chili: #A4E245;

$guacamole: #B4D27E;
$huckleberry: #FF5148;
$graphite: #303030;
$ashphalt: #202020;
$midnight: #050505;
$midnight75: rgba(5, 5, 5, 0.75);
$buttermilk: #FEFEFA;

/* SIZES */
$sides: 20px;
$standard: 60px;
$condensed: 45px;
$menu-item: 120px;
$leaderboard-tally-width: 40px;

$padding-large: 30px;
$padding-standard: 15px;
$padding-small: 3px;

/* Z-INDEXES */
$loader: 150;
$score: 125;
$navbar: 100;
$menu: 50;

/* ANIMATIONS */
$duration: 250ms;

/* MISC */
$disabled: .3;
$box-shadow: 0 0 15px rgba(5, 5, 5, .5);
