@import '../../styles/variables';
@import '../../styles/typography';

.SearchBar {

    position: relative;

    width: calc(100% - (#{$padding-standard} * 2));
    height: $condensed;
    margin: $padding-large auto;

    background-color: $ashphalt;

    border-radius: $condensed;
    border: 2px solid $guacamole;

}

.SearchBar input {

    @extend .Default;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: $buttermilk;

    appearance: none;
    border: none;
    border-radius: inherit;
    outline: none;
    background: none;

    width: 100%;
    height: 100%;
    padding: 3px $padding-standard 0;

}

.SearchBar input::placeholder {
    color: $buttermilk;
    opacity: $disabled;
}

.SearchBar .icon {

    position: absolute;
    top: 0;
    right: 0;

    width: $condensed;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $guacamole;

    border-top-right-radius: $condensed;
    border-bottom-right-radius: $condensed;

}

.SearchBar .icon img {
    width: 24px;
    height: 24px;
}
