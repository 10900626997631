@import '../../styles/variables';
@import '../../styles/typography';

.CreateTeeColor {
    padding: $padding-standard;
}

.CreateTeeColor .Input {
    margin-bottom: $padding-small;
    &:nth-child(3) { margin-bottom: $padding-standard; }
}

.CreateTeeColor .CreateCourseHeader {
    margin-bottom: $padding-small;
}

.CreateTeeColor .HoleRow {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.CreateTeeColor .Button {
    margin: $padding-large auto $padding-standard;
}
