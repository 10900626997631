@import '../../styles/variables';
@import '../../styles/typography';

.Menu {

    position: fixed;
    top: $standard;
    left: 0;
    z-index: $menu;

    width: 100%;
    height: calc(100% - #{$standard});
    padding: $padding-standard;

    background-color: $midnight;

}

.Menu .Notification {
    margin-bottom: $padding-small;
}

.Menu .Button {
    margin-top: $padding-large;
}

.Menu .ActionButton[data-id="info"],
.Menu .ActionButton[data-id="logout"] {
    position: fixed;
    bottom: $padding-standard;
}

.Menu .ActionButton[data-id="info"] {
    left: $padding-standard;
}

.Menu .ActionButton[data-id="logout"] {
    right: $padding-standard;
}

.Menu .ActionButton[data-id="logout"] img {
    width: 28px;
    height: 28px;
    margin-left: 4px;
}
