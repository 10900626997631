@import '../../styles/variables';
@import '../../styles/typography';

.LeaderboardHeader {

    position: fixed;
    top: $standard + ($padding-large * 2) + $condensed;
    left: $padding-standard;
    z-index: 5;

    width: calc(100% - (#{$padding-standard} * 2));
    height: 30px;
    padding: 3px 0 0 $padding-standard;

    box-shadow: $box-shadow;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $ashphalt;

}

.LeaderboardHeader.results {

    position: relative;
    top: auto;
    left: auto;

    width: 100%;
    padding: 3px 10px 0 $padding-standard;

}

.LeaderboardHeader h4 {
    @extend .Notification_Text;
    font-weight: 300;
    text-transform: uppercase;
}

.LeaderboardHeader div.tallies {
    display: flex;
    align-items: center;
}

.LeaderboardHeader div.tallies h4 {
    text-align: center;
    width: $leaderboard-tally-width;
}

.LeaderboardHeader div.tallies h4:nth-of-type(2) {
    padding-left: 5px;
}

.LeaderboardHeader div.tallies div.results h4 {
    // width: $leaderboard-tally-width / 1.5;
}

.LeaderboardHeader div.tallies > div {
    display: flex;
}
