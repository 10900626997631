@import '../../styles/variables';
@import '../../styles/typography';

.Tournament {
    padding: ($padding-standard + 30px + $padding-small) $padding-standard (50px + ($padding-standard * 2));
}

.Tournament .headerWrapper {

    position: fixed;
    top: $standard;
    left: 0;

    width: 100%;
    padding: $padding-standard $padding-standard 0;

    background-color: $midnight;

}

.Tournament .ActionButton {
    position: fixed;
    bottom: $padding-standard;
    left: $padding-standard;
}
