@import '../../styles/variables';

.Loader {

    position: fixed;
    top: $standard;
    left: 0;
    z-index: $loader;

    width: 100%;
    height: calc(100% - #{$standard});

    background-color: $midnight;

    display: flex;
    align-items: center;
    justify-content: center;

}

.Loader div.circle {

    width: 45px;
    height: 45px;

    border-radius: 100%;
    border: 5px solid;
    border-top-color: $guacamole;
    border-bottom-color: rgba(255, 255, 255, .3);
    border-left-color: $guacamole;
    border-right-color: rgba(255, 255, 255, .3);

    animation: spin 1s ease-in-out infinite;

}

@keyframes spin {
    from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
}
