@import '../../styles/variables';
@import '../../styles/typography';

.ScorecardSticky {

    position: fixed;
    top: $standard + ($padding-large * 2) + $condensed;
    left: $padding-standard;
    z-index: 5;

    display: flex;

    background-color: $ashphalt;
    box-shadow: 0 0 25px $midnight;

    width: calc(100% - (#{$padding-standard} * 2));
    height: $standard;

}

.ScorecardSticky > div {
    width: 25%;
    height: 100%;
}

.ScorecardSticky div.golfer {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 5px;

    text-align: center;
    overflow: hidden;

    border-right: 1px solid $graphite;
    &:last-child { border-right: none; }

}

.ScorecardSticky div.golfer h3,
.ScorecardSticky div.golfer div.handicap {
    font-size: 14px;
    line-height: 15px;
}

.ScorecardSticky div.golfer h3 {
    font-weight: 300;
}

.ScorecardSticky div.golfer div.handicap {

    font-weight: 700;

    display: flex;
    align-items: center;

    margin-top: 3px;

}

.ScorecardSticky div.golfer div.handicap div.dot {

    width: 6px;
    height: 6px;

    background-color: $chili;
    border-radius: 50%;

    margin: 1px 3px 0 0;

}
