@import '../../styles/variables';
@import '../../styles/typography';

.Stats {
    padding: $padding-standard;
}

.Stats .Input {
    margin-top: $padding-small;
    &:first-of-type { margin-top: 0; }
}

.Stats .golfers {
    margin-top: $padding-standard;
}

.Stats .GolferRow {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}
