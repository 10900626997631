@import '../../styles/variables';
@import '../../styles/typography';

.ActionButton {

    appearance: none;
    border: none;
    border-radius: 50%;
    outline: none;

    box-shadow: $box-shadow;

    background-color: $guacamole;

    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 5;

    cursor: pointer;

}

.ActionButton img {
    width: 32px;
    height: 32px;
}

.ActionButton[data-id="edit"] img {
    width: 22px;
    height: 22px;
}
