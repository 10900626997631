@import '../../styles/variables';
@import '../../styles/typography';

.EditCourse {
    padding: 0 $padding-standard (50px + ($padding-standard * 2));
}

.EditCourse .Input {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}

.EditCourse .Input:nth-child(3) {
    margin-bottom: $padding-standard;
}

.EditCourse .Button {
    margin: $padding-large auto $padding-standard;
}

.EditCourse .ActionButton {
    position: fixed;
    bottom: $padding-standard;
}

.EditCourse .ActionButton[data-id="info"] {
    left: $padding-standard;
}

.EditCourse .ActionButton[data-id="create"] {
    right: $padding-standard;
}

.EditCourse .CreateCourseHeader {
    margin-bottom: $padding-small;
}

.EditCourse .HoleRow {
    margin-bottom: $padding-small;
    &:last-of-type { margin-bottom: 0; }
}
