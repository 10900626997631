@import '../../styles/variables';
@import '../../styles/typography';

.GolferRow {

    width: 100%;
    height: $standard;
    padding: 0 $padding-standard;

    background-color: $ashphalt;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.GolferRow .left {
    display: flex;
    align-items: center;
}

.GolferRow .left .icon {

    display: block;

    width: 30px;
    height: 30px;
    margin-right: 10px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: $box-shadow;

    background-size: cover;
    background-position: center center;
    background-color: $graphite;

    @extend .CTA;
    font-family: 'Rammetto One', sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;

}

.GolferRow h2 {
    @extend .Default;
}

.GolferRow h2 sup {

    font-size: 70%;
    vertical-align: top;

    margin-left: 1px;

}

.GolferRow .left h3 {

    @extend .Notification_Text;
    font-size: 12px;
    color: $guacamole;

    margin-top: 1px;

}

.GolferRow .left > div {
    padding-top: 2px;
    flex: 1;
}

.GolferRow .right {
    padding-top: 2px;
}

.GolferRow .right h2 {
    font-weight: 700;
    color: $guacamole;
}
