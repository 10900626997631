@import './styles/variables';
@import './styles/typography';

* {
    box-sizing: border-box;
}

html, body, div#root {
    height: 100%;
}

html {
    font-size: 100%;
    letter-spacing: .025em;
}

body {

    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: $buttermilk;
    -webkit-font-smoothing: antialiased;

    background-color: $midnight;

}

a {
    color: inherit;
    text-decoration: none;
}

.CenterAll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification {

    @extend .SubheaderText;
    text-transform: unset;

    padding: 15px $sides;

    background-color: $midnight;

    display: flex;
    align-items: center;

}

.notification.small {
    @extend .MetaTextSub;
}

/*
div.icon {

    position: relative;

    width: 30px;
    height: 30px;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Rammetto One', sans-serif;
    font-size: 20px;
    line-height: 20px;

}
*/

div.back-button {

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;

    width: 100%;

    box-shadow: $box-shadow;
    border-top: 1px solid $midnight;

}
